@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-snow h1 {
  font-size: 25px !important;
}
.ql-snow h2 {
  font-size: 20px !important;
}
.ql-snow h3 {
  font-size: 18px !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
  border-radius: 10px 10px 0 0;
}

.quill > * {
  border-color: inherit !important;
  color: inherit !important;
}

.quill > .ql-container {
  /* border radius of the container and for font size*/
  font-size: inherit;
  border-radius: 0 0 10px 10px;
}

.ql-snow .ql-picker {
  color: inherit !important;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
  /* for placeholder */
  color: inherit;
}
.ql-snow.ql-toolbar button svg {
  opacity: 0.76;
  color: currentColor;
}
.ql-snow .ql-stroke {
  /* for the border of the editor */
  stroke: currentColor !important;
}
.ql-snow .ql-fill {
  /* for the bg color */
  fill: currentColor !important;
}
.ql-picker-item {
  /* for dropdown */
  color: #444 !important;
}
